var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"style_box"},[_c('div',[_c('div',{staticClass:"title_box"},[_c('div',{staticClass:"title_text"},[_c('Icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"ios-arrow-down"}}),_vm._v("图片样式 ")],1)]),_c('div',{staticClass:"grid"},_vm._l((_vm.grid_items),function(item,index){return _c('div',{key:index,class:[
                            { active: _vm.formData.imgtype === index },
                            'default' ],on:{"click":function($event){return _vm.toggleItem(index, item.num)}}},[_c('img',{attrs:{"src":index == _vm.formData.imgtype
                                    ? item.activeImageSrc
                                    : item.imageSrc,"alt":item.altText,"width":item.width,"height":item.height},on:{"dragstart":function($event){$event.preventDefault();}}}),_c('div',{class:{ activeP: _vm.formData.imgtype === index },staticStyle:{"user-select":"none"}},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0),_c('div',{staticClass:"title_box"},[_c('div',{staticClass:"title_text"},[_c('Icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"ios-arrow-down"}}),_vm._v("图片编辑 ")],1)]),_c('div',{staticClass:"banner_box"},[_c('Upload',{ref:"upload",attrs:{"show-upload-list":false,"format":['jpg', 'jpeg', 'png', 'gif'],"max-size":2048,"headers":_vm.header,"on-format-error":_vm.handleFormatError,"on-success":_vm.picImgSuccess,"on-progress":_vm.handleProgress,"action":_vm.uploads}},[_c('div',{staticClass:"absolute_div"},[_c('Icon',{staticClass:"absolute_icon",attrs:{"type":"md-add"}})],1),_c('Icon',{staticClass:"icon",attrs:{"type":"md-close-circle","color":"black","size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemove()}}})],1),(
                            _vm.formData.content.imgs.length - 1 >=
                            this.formData.imgkey
                        )?[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.formData.content.imgs[this.formData.imgkey]
                                    .picview,"alt":""}})]:[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.formData.content.imgs[0].picview,"alt":""}})]],2),_c('div',[_c('Form',{attrs:{"label-position":"right","label-width":70}},[_c('FormItem',{staticStyle:{"margin":"0 0 10px 0"},attrs:{"label":"图片尺寸"}},[_c('div',{staticClass:"just_sb",staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticClass:"display_flex"},[_c('div',{staticClass:"kuai_box"},[_vm._v("高")]),_c('InputNumber',{staticStyle:{"width":"120px"},attrs:{"max":500,"min":0,"precision":0},model:{value:(_vm.formData.content.height),callback:function ($$v) {_vm.$set(_vm.formData.content, "height", $$v)},expression:"formData.content.height"}})],1)])]),_vm._t("default")],2)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }