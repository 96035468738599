<template>
  <div>
    <div>
      <!--formData.type==5 宫格组件 -->
      <div class="style_box">
        <div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />宫格编辑
            </div>
          </div>
          <div style="margin: 10px 0">
            <Form label-position="right" :label-width="70">
              <FormItem style="margin: 0 0 10px 0" label="背景色">
                <ColorPicker v-model="formData.content.bgColor" />
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="上下间距">
                <Slider
                  v-model="formData.content.paddingData.topBottom"
                ></Slider>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="左右间距">
                <Slider
                  v-model="formData.content.paddingData.leftRight"
                ></Slider>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="图标尺寸">
                <div style="margin-bottom: 5px" class="just_sb">
                  <div class="display_flex">
                    <div class="kuai_box">宽</div>
                    <Input
                      v-model="formData.content.width"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                  <div class="display_flex">
                    <div class="kuai_box">高</div>
                    <Input
                      v-model="formData.content.height"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                </div>
              </FormItem>
              <FormItem label="字体大小">
                <div class="just_sb">
                  <Input
                    v-model="formData.content.size"
                    placeholder="请输入字号"
                    style="width: 240px"
                  ></Input>
                  <ColorPicker v-model="formData.content.textColor" />
                </div>
              </FormItem>
              <FormItem label="宫格说明">
                <span style="color: #999" class="tishi_text mr-5">隐藏</span>
                <i-switch
                  v-model="formData.content.status"
                  :true-value="1"
                  :false-value="0"
                />
                <span style="color: #ff761d" class="tishi_text ml-5">显示</span>
              </FormItem>
            </Form>
          </div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />宫格内容
            </div>
            <div>
              <Icon
                @click="addIcon"
                class="add_icon"
                type="ios-add-circle-outline"
              />
            </div>
          </div>
          <div
            v-for="(item, index) of formData.content.iconList"
            :key="index"
            class="item_icon_box"
          >
            <div class="icon_menu_box">
              <img :src="item.picview" alt="" />
              <img
                @click.stop="delIconBtn(index)"
                class="del_icon_menu"
                src="@/assets/imgs/del.png"
                alt=""
              />
              <Upload
                ref="upload"
                :show-upload-list="false"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="2048"
                :headers="header"
                :on-format-error="handleFormatError"
                :on-success="(res) => iconSuccess(res, item)"
                :on-progress="handleProgress"
                multiple
                :action="uploads"
              >
              <Icon class="absolute_icon" type="md-add" />
              </Upload>
            </div>
            <div>
              <div style="margin-bottom: 5px" class="just_sb">
                <div class="display_flex mr-5">
                  <div class="kuai_box">动作</div>
                  <Select style="width: 110px" v-model="item.action">
                    <Option
                      v-for="item in actionList"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </div>
                <div v-if="item.action == 1" class="display_flex">
                  <div class="kuai_box">跳转<br />页面</div>
                  <Select style="width: 110px" v-model="item.path">
                    <Option
                      v-for="item in pageList"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </div>
                <div v-if="item.action == 2" class="display_flex">
                  <div class="kuai_box">跳转<br />商品</div>
                  <Select style="width: 110px" v-model="item.shop">
                    <Option
                      v-for="item in shopList"
                      :value="item.id"
                      :key="item.id"
                      >{{ item.goods_name }}</Option
                    >
                  </Select>
                </div>
              </div>
              <div class="just_sb">
                <div class="display_flex">
                  <div class="kuai_box">标题</div>
                  <Input
                    placeholder="标题"
                    v-model="item.title"
                    style="width: 110px"
                  ></Input>
                </div>
                <div class="display_flex">
                  <div class="kuai_box">说明</div>
                  <Input
                    placeholder="功能描述"
                    v-model="item.explain"
                    style="width: 110px"
                  ></Input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import { getCookies } from "@/utils/helper";
export default {
  props: {
    formData: {
      type: Object,
      default: {},
    },
    actionList: {
      type: Array,
      default: [],
    },
    pageList: {
      type: Array,
      default: [],
    },
    shopList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      header: { "author-token": getCookies("canyin_token") },
      uploads: config.apiUrl + "/storeapi/data/uploads",
    };
  },
  methods: {
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    // 图标列表上传
    iconSuccess(e, item) {
      item.picview = e.data.path;
      item.value = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 添加icon列表
    addIcon() {
      this.formData.content.iconList.push({
        value: "/img/diy/smail.png",
        picview: "http://canyin.hulianzaowu.top/img/diy/smail.png",
        action: 1,
        path: null,
        shop: null,
        title: "标题",
        explain: "功能描述",
      });
    },
    // 删除icon列表
    delIconBtn(e) {
      if (this.formData.content.iconList.length == 1) {
        this.$Message.info("至少保留一个");
      } else {
        this.formData.content.iconList.splice(e, 1);
      }
    },
  },
};
</script>

<style scoped>
.style_box {
  padding: 0 10px;
}
.title_box {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.title_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.display_flex {
  display: flex;
}
.kuai_box {
  width: 30px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #bfbfbf;
}
.just_sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tishi_text {
  font-size: 12px;
}

.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.add_icon {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
}
.item_icon_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.icon_menu_box {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.icon_menu_box img {
  width: 100%;
  height: 100%;
}
.del_icon_menu {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
}
.absolute_icon {
  position: absolute;
  font-size: 50px;
  cursor: pointer;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>