<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div>
        <Icon size="24" type="ios-arrow-back" />
      </div>
      <div class="ml10">分享有礼</div>
    </div>
    <div class="content_box">
      <div>
        <Button @click="cardModalBtn('add')" class="btn">创建分享活动</Button>
      </div>
      <Table stripe style="margin-top: 20px" :columns="columns12" :data="data6">
        <template slot-scope="{ index }" slot="index">
          <strong>{{ index + 1 }}</strong>
        </template>
        <template slot-scope="{ row }" slot="type">
          <div>
            {{
              row.page_type === 1 ? "首页" : row.page_type === 2 ? "商品页" : ""
            }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="join">
          <div v-for="(item, index) in row.joins" :key="index">
            {{
              item === "1"
                ? "新客"
                : item === "2"
                ? "会员"
                : item === "3"
                ? "用户"
                : ""
            }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <Button
            type="success"
            ghost
            size="small"
            style="margin-right: 5px"
            v-if="row.status != 1"
            @click="push(row)"
            >启用</Button
          >
          <Button
            type="warning"
            ghost
            size="small"
            v-if="row.status == 1"
            @click="push(row)"
            style="margin-right: 5px"
            >停止</Button
          >
          <Button
            type="primary"
            @click="cardEdit(row)"
            ghost
            v-if="row.status != 1"
            size="small"
            style="
              margin-right: 5px;
              color: #2d8cf0;
              border: 1px solid #2d8cf0;
              background: none;
            "
            >编辑</Button
          >
          <Button type="error" ghost size="small" @click="removeIt(row)"
            >删除</Button
          >
        </template>
      </Table>
      <br />
      <Page
        :total="total"
        :current="storePrams.page"
        style="text-align: right"
        show-elevator
        show-total
        @on-change="pageChange"
        :page-size="storePrams.limit"
      />
    </div>
    <Modal
      v-model="cardModal"
      :title="cardtitle"
      width="600"
      @on-cancel="cardCancel"
    >
      <div class="body_box">
        <Form style="width: 450px" label-position="left" :label-width="110">
          <FormItem label="活动名称：">
            <Input
              placeholder="请输入活动名称"
              v-model="formData.activity_title"
            ></Input>
          </FormItem>
          <FormItem label="转发给朋友：">
            <div style="display: flex">
              <div>
                <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :headers="header"
                    :on-format-error="handleFormatError"
                    :on-success="uploadImgSuccess"
                    :on-progress="handleProgress"
                    multiple
                    :action="uploads">
                <div class="uplads_box">
                  <div style="text-align: center">
                    <div style="margin-top: -10px">
                      <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                    </div>
                    <div
                      class="font14"
                      style="margin-top: -10px; color: #9ca7b9"
                    >
                      点击上传
                    </div>
                  </div>
                </div>
                </Upload>
              </div>
              <div class="upPic" v-if="activityPic">
                <img :src="activityPic" alt="" />
              </div>
            </div>
          </FormItem>
          <FormItem label="选择页面：">
            <Select v-model="formData.page_type">
              <Option
                v-for="item in cityList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
          <!--          <FormItem label="商品：" v-if="formData.page_type == 2">-->
          <!--            <Select v-model="formData.goods_id">-->
          <!--              <Option v-for="item in cityList2" :value="item.id" :key="item.id">{{ item.goods_name }}</Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
          <FormItem label="分享时标题：">
            <Input
              placeholder="请输入分享时标题"
              v-model="formData.share_title"
            ></Input>
          </FormItem>
          <FormItem label="分享到朋友圈：">
            <div style="display: flex">
              <div>
                <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :headers="header"
                    :on-format-error="handleFormatError"
                    :on-success="uploadImgActivity"
                    :on-progress="handleActivity"
                    multiple
                    :action="uploads">
                <div class="uplads_box">
                  <div style="text-align: center">
                    <div style="margin-top: -10px">
                      <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                    </div>
                    <div
                      class="font14"
                      style="margin-top: -10px; color: #9ca7b9"
                    >
                      点击上传
                    </div>
                  </div>
                </div>
                </Upload>
              </div>
              <div class="upPic" v-if="sharePic">
                <img :src="sharePic" alt="" />
              </div>
            </div>
          </FormItem>
          <FormItem label="分享时简介：">
            <textarea
              placeholder="请输入分享时简介"
              v-model="formData.share_desc"
              class="textar"
            ></textarea>
          </FormItem>
          <FormItem label="分享时赠送：">
            <Select v-model="formData.share_give">
              <Option
                v-for="item in cityList1"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
          <FormItem label="成长值：" v-if="formData.share_give == 1">
            <Input
              placeholder="请输入成长值："
              v-model="formData.give_val"
            ></Input>
          </FormItem>
          <FormItem label="卡券：" v-if="formData.share_give == 2">
            <CheckboxGroup v-model="formData.coupon_ids">
              <div
                class="chooseeMore"
                v-for="(item, index) in cityList3"
                :key="index"
              >
                <Checkbox :label="item.id">{{ item.coupon_name }}</Checkbox>
              </div>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="参与人群：">
            <CheckboxGroup v-model="formData.joins">
              <Checkbox label="1">新客</Checkbox>
              <Checkbox label="2">会员</Checkbox>
              <Checkbox label="3">普通用户</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="开始日期：">
            <DatePicker
              v-model="formData.start_date"
              type="date"
              placement="bottom-end"
              placeholder="点击选择开始日期"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
          <FormItem label="结束日期：">
            <DatePicker
              v-model="formData.end_date"
              type="date"
              placement="bottom-end"
              placeholder="点击选择结束日期"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cardCancel">取消</Button>
        <Button class="btn" @click="cardadd">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { sharelist } from "@/api/setting";
import {
  getGoods,
  shareAdd,
  getCoupon,
  publish,
  shareDel,
  shareUpdate,
  shareClear,
} from "../../../../api/elseSetting";
import config from '@/config';
import {getCookies} from '@/utils/helper'
import Moment from "moment";

export default {
  name: "password",
  data() {
    return {
      header: {
          'author-token':getCookies('canyin_token')},
      uploads: config.apiUrl + '/storeapi/data/uploads',
      cardtitle: "创建卡卷",
      cardModal: false,
      total: 0,
      storePrams: {
        page: 1,
        limit: 10,
      },
      formData: {
        activity_title: "",
        activity_img: "",
        page_type: "",
        goods_id: 0,
        share_title: "",
        share_cover: "",
        joins: [],
        share_give: "",
        give_val: 0,
        coupon_ids: [],
        start_date: "",
        end_date: "",
        share_desc: "",
      },
      allTime: [],
      cityList: [
        {
          value: 1,
          label: "首页",
        },
      ],
      cityList1: [
        {
          value: 1,
          label: "成长值",
        },
        {
          value: 2,
          label: "卡券",
        },
      ],
      cityList2: [],
      cityList3: [],
      columns12: [
        {
          title: "#",
          slot: "index",
          width: 60,
          align: "center",
        },
        {
          title: "活动名称",
          key: "activity_title",
          align: "center",
        },
        {
          title: "页面名称",
          key: "page_type",
          slot: "type",
          align: "center",
        },
        {
          title: "参与人群",
          key: "joins",
          slot: "join",
          align: "center",
        },
        {
          title: "时间限制",
          width: 205,
          key: "time",
          align: "center",
        },
        {
          title: "启用时间",
          key: "publish_time",
          align: "center",
        },
        {
          title: "停止时间",
          key: "end_date",
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          align: "center",
        },
        {
          title: "操作",
          width: 200,
          fixed: "right",
          align: "center",
          slot: "action",
        },
      ],
      data6: [],
      sharePic: "",
      activityPic: "",
      needId: "",
    };
  },
  created() {
    this.getList();
    this.getCommodity();
    this.getCard();
  },
  methods: {
    getList() {
      sharelist(this.storePrams).then((res) => {
        this.data6 = res.data.data || [];
        this.total = res.data.total;
      });
    },
    push(e) {
      let status = 0,
        msg = "请问您是否确认启用活动，确定无误后点击确认发布。";
      if (e.status == 0) {
        status = 1;
      } else if (e.status == 1) {
        status = 2;
        msg = "请问您是否确认停止活动，确定无误后点击确认停止。";
      } else if (e.status == 2) {
        status = 1;
      }
      this.$Modal.confirm({
        title: "发布提示",
        content: msg,
        onOk: () => {
          publish({ id: e.id, status: status })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((res) => {
              this.$Message.error(res.msg);
            });
        },
        onCancel: () => {},
      });
    },
    getCommodity() {
      getGoods().then((res) => {
        this.cityList2 = res.data || [];
      });
    },
    removeIt(row) {
      this.$Modal.confirm({
        title: "删除提示",
        content: "请问您是否确认删除此活动，删除后您将无法恢复！！！",
        onOk: () => {
          shareClear({ id: row.id })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((res) => {
              this.$Message.error(res.msg);
            });
        },
        onCancel: () => {},
      });
    },
    getCard() {
      getCoupon().then((res) => {
        this.cityList3 = res.data || [];
      });
    },
    cardModalBtn(e) {
      if (e == "add") {
        this.cardtitle = "新建活动";
        this.cardModal = true;
      }
    },
    cardEdit(e) {
      // this.needId = e.id
      shareDel({ id: e.id })
        .then((res) => {
          this.cardtitle = "编辑活动";
          this.cardModal = true;
          this.activityPic = res.data.activity_img_domain;
          this.sharePic = res.data.share_cover_domain;
          this.formData = {
            activity_title: res.data.activity_title,
            activity_img: res.data.activity_img,
            page_type: res.data.page_type,
            goods_id: res.data.goods_id,
            share_title: res.data.share_title,
            share_cover: res.data.share_cover,
            joins: res.data.joins,
            share_give: res.data.share_give,
            give_val: res.data.give_val,
            coupon_ids: res.data.coupon_ids,
            share_desc: res.data.share_desc,
            start_date: res.data.start_date,
            end_date: res.data.end_date,
            id: res.data.id,
          };
        })
        .catch((res) => {
          console.log(res);
        });
    },
    cardCancel() {
      this.clearForm();
      this.cardModal = false;
    },
    cardadd() {
      if (this.cardtitle == "新建活动") {
        this.formData.start_date = Moment(this.formData.start_date).format(
          "YYYY-MM-DD"
        );
        this.formData.end_date = Moment(this.formData.end_date).format(
          "YYYY-MM-DD"
        );
        shareAdd(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.cardModal = false;
            this.clearForm();
            this.getList();
          })
          .catch((res) => {
            this.$Message.error(res.msg);
          });
      } else {
        this.formData.start_date = Moment(this.formData.start_date).format(
          "YYYY-MM-DD"
        );
        this.formData.end_date = Moment(this.formData.end_date).format(
          "YYYY-MM-DD"
        );
        shareUpdate(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.cardModal = false;
            this.clearForm();
            this.getList();
          })
          .catch((res) => {
            this.$Message.error(res.msg);
          });
      }
    },
    clearForm() {
      this.activityPic = "";
      this.sharePic = "";
      this.formData = {
        activity_title: "",
        activity_img: "",
        page_type: "",
        goods_id: 0,
        share_title: "",
        share_cover: "",
        joins: [],
        share_give: "",
        give_val: 0,
        coupon_ids: [],
        start_date: "",
        end_date: "",
        share_desc: "",
      };
    },
    pageChange(index) {
      this.storePrams.page = index;
      this.getList();
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e) {
      this.activityPic = e.data.path;
      this.formData.activity_img = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    //编辑图片上传图片成功
    uploadImgActivity(e) {
      this.sharePic = e.data.path;
      this.formData.share_cover = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleActivity() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    backBtn() {
      this.$emit("backview", 0);
    },
  },
};
</script>

<style scoped>
/* .son-page{

} */
.back_box {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 30px;
}
.content_box {
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-top: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
}
.body_box {
  width: 100%;
  height: 500px;
  overflow: auto;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.textar {
  border: 1px solid #dcdee2;
  outline: none;
  width: 300px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
.upPic {
  margin-left: 20px;
  width: 105px;
  height: 105px;
  position: relative;
}
.upPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.chooseeMore {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
