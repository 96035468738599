<template>
  <div>
    <div @click="backBtn" class="back_box">
      <div class="pointer">
        <Icon style="color: #515a6e" size="24" type="ios-arrow-back" />
      </div>
      <div class="ml10">积分商城</div>
    </div>
    <div class="son-page">
      <div class="top-btns">
        <!-- <div class="l-btn r-set">本地上传</div> -->
        <div class="title">
          本地上传<span style="color: #999; font-size: 12px">（415*300）</span>
        </div>
        <div v-if="viewPic.length != 0" @click="adduplods" class="l-btn">
          确认上传
        </div>
      </div>
      <div class="update-pic">
        <div v-for="(item, index) of viewPic" :key="index" class="image-list">
          <img :src="item" alt="" />
        </div>
        <div class="image-list">
          <Upload
            ref="upload"
            :show-upload-list="false"
            :format="['jpg', 'jpeg', 'png']"
            :max-size="2048"
            :headers="header"
            :on-format-error="handleFormatError"
            :on-success="uploadImgSuccess"
            :on-progress="handleProgress"
            multiple
            type="drag"
            :action="uploads"
            style="display: inline-block; width: 100%; height: 180px"
          >
          <div
            style="display: inline-block; width: 100%; height: 180px"
          >
            <div
              style="
                width: 100%;
                height: 180px;
                line-height: 180px;
                text-align: center;
              "
            >
              <Icon type="md-add" size="50" />
            </div>
          </div>
          </Upload>
        </div>
      </div>
      <div class="already">
        <div class="title">banner图片</div>
        <div class="update-pic">
          <draggable
            class="syllable_ul"
            element="ul"
            :list="imgList"
            :options="{ group: 'title', animation: 150 }"
            :no-transition-on-drag="true"
            @change="change"
            @start="start"
            @end="end"
            :move="move"
          >
            <transition-group
              style="display: flex; align-items: center; flex-wrap: wrap"
              type="transition"
              :name="!drag ? 'syll_li' : null"
              :css="true"
            >
              <div
                v-for="(item, index) of imgList"
                :key="index + 'S'"
                class="image-list"
              >
                <Icon
                  @click="close_Btn(index)"
                  class="colse_DW"
                  type="ios-close-circle"
                />
                <img :src="item" alt="" />
                <div style="text-align: center">{{ index + 1 }}</div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bannerList, addBanner, bannerDel } from "@/api/setting";
import config from "@/config";
import { getCookies } from "@/utils/helper";
import draggable from "vuedraggable";
export default {
  components: { draggable, },
  name: "picture",
  data() {
    return {
      header: {
        "author-token": getCookies("canyin_token"),
      },
      drag: false,
      uploads: config.apiUrl + "/storeapi/data/uploads",
      viewPicadd: [],
      viewPic: [],
      images: [],
      imgList: [],
    };
  },
  created() {
    this.getPic();
  },
  methods: {
    backBtn() {
      this.$parent.goback();
    },
    getPic() {
      bannerList().then((res) => {
        this.imgList = res.data.banner_domain;
        this.images = res.data.banner;
      });
    },
    close_Btn(e) {
      this.imgList.splice(e, 1);
      this.images.splice(e, 1);
      bannerDel({ banner: this.images })
        .then(() => {})
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    adduplods() {
      addBanner({ banner: this.viewPicadd })
        .then((res) => {
          this.$Message.success(res.msg);
          this.images = [];
          this.viewPic = [];
          this.viewPicadd = [];
          this.getPic();
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    change() {},
    //start ,end ,add,update, sort, remove 得到的都差不多
    start() {
      this.drag = true;
    },
    end(evt) {
      this.drag = true;
      evt.item; //可以知道拖动的本身
      evt.to; // 可以知道拖动的目标列表
      evt.from; // 可以知道之前的列表
      evt.oldIndex; // 可以知道拖动前的位置
      evt.newIndex; // 可以知道拖动后的位置
      bannerDel({ banner: this.imgList })
        .then(() => {})
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    move() {},
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //添加图片上传图片成功
    uploadImgSuccess(e) {
      this.viewPic.push(e.data.path);
      this.viewPicadd.push(e.data.src);
      this.$Message.destroy();
      // this.$Message.success(e.msg);
    },
    handleProgress() {
      const msg = this.$Message.loading({
        content: "处理中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
  },
};
</script>

<style scoped>
.back_box {
  width: 100%;
  cursor: pointer;
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
}
.son-page {
  padding: 30px 10px 30px 10px;
  margin-top: 30px;
  height: 75vh;
  overflow: auto;
  background: #fff;
  box-sizing: border-box;
}

.top-btns {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
}
/* .ivu-icon{
  color: #ff5c11!important;
} */
.l-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  background: #ff761d;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 15px;
  margin-right: 20px;
  margin-left: 20px;
}
.colse_DW {
  position: absolute;
  color: #999 !important;
  font-size: 30px;
  top: -14px;
  right: -14px;
  z-index: 99;
}

.r-set {
  color: #585858;
  border: 1px solid #cccccc;
  background: white;
}

.update-pic {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.image-list {
  border: 1px solid #eee;
  position: relative;
  width: 245px;
  height: 180px;
  /* background: #555555; */
  border-radius: 4px;
  margin-bottom: 25px;
  margin-left: 20px;
}
.image-list img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.already .title {
  font-size: 18px;
  font-weight: bold;
  color: #343434;
  margin-left: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.already .title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 17px;
  background: #ff5c11;
  border-radius: 3px;
  margin-right: 10px;
}
.top-btns .title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 17px;
  background: #ff5c11;
  border-radius: 3px;
  margin-right: 10px;
}
.top-btns .title {
  font-size: 18px;
  font-weight: bold;
  color: #343434;
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
  /* margin-bottom: 30px; */
}
</style>
