<template>
  <div>
    <div v-if="Select == 0" class="son-page">
      <div @click="menuclick(item)" :style="'background:url('+item.icon+') no-repeat 100% 100%;'" v-for="(item,index) of menus" :key="index" class="item_box">
        <div>{{item.title}}</div>
      </div>
    </div>
    <div v-if="Select == 'Sharepolitely'"><Share @backview="backview"></Share></div>
    <div v-if="Select == 'Skydropredenvelope'"><Envelopes @backview="backview"></Envelopes></div>
    <div v-if="Select == 'Newcomerreduction'"><Order @backview="backview"></Order></div>
    <div v-if="Select == 'Queuefornumber'"><Queuenumber @backview="backview"></Queuenumber></div>
    <div v-if="Select == 'Integralmall'"><Integralmall @backview="backview"></Integralmall></div>
    <div v-if="Select == 'Facepayment'"><Facepayment @backview="backview"></Facepayment></div>
    <div v-if="Select == 'Rechargeactivity'"><Rechargeactivity @backview="backview"></Rechargeactivity></div>
    <div v-if="Select == 'Mustordergoods'"><Mustordergoods @backview="backview"></Mustordergoods></div>
    <div v-if="Select == 'Teafee'"><Teafee @backview="backview"></Teafee></div>
    <div v-if="Select == 'Shopdecoration'"><Templateselection @backview="backview"></Templateselection></div>
    <div v-if="Select == 'Multipleaddress'"><Multipleaddress @backview="backview"></Multipleaddress></div>
    <div v-if="Select == 'Winestorage'"><Winestorage @backview="backview"></Winestorage></div>
    <div></div>
  </div>
</template>

<script>
import Envelopes from './marketing/envelopes'
import Order from './marketing/order'
import Share from './marketing/share'
import Queuenumber from './marketing/queuenumber'
import Integralmall from './integralmall/index'
import Facepayment from './marketing/facepayment'
import Rechargeactivity from './marketing/rechargeactivity'
import Mustordergoods from './marketing/mustordergoods'
import Teafee from './marketing/teafee'
import Shopdecoration from './marketing/shopdecoration'
import Templateselection from './marketing/templateselection'
import Multipleaddress from './marketing/multipleaddress'
import Winestorage from './marketing/winestorage'
import {getCookies} from '@/utils/helper'
export default {
  components: {Envelopes,Order,Share,Queuenumber,Integralmall,Facepayment,Rechargeactivity,Mustordergoods,Teafee,Shopdecoration,Templateselection,Multipleaddress,Winestorage},
  name: "password",
  data() {
    return {
      Select:0,
      menus:[]
    }
  },
  created() {
    this.menus = this.$store.state.plugin
  },
  methods: {
    menuclick(e){
      if (e.title=='收银') {
        let url = `${location.origin}/cash/#/?token=${getCookies('canyin_token')}`
        window.open( url , '_blank' )
      }else if(e.title=='积分商城'){
        this.$parent.replace();
        this.Select = e.src
      }else{
        this.Select = e.src
      }
    },
    goback(){
      this.$parent.goback()
    },
    backview(e){
      this.Select = e
    },
  }
}
</script>

<style scoped>
.son-page{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.item_box{
  cursor: pointer;
  width: 23%;
  height: 100px;
  border-radius: 12px;
  background: #fff;
  margin: 10px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
