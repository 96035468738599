<template>
  <div class="son-page">
    <div @click="backBtn" class="back_box">
      <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
      <div class="ml10">支付宝</div>
    </div>
    <div class="content_box">
      <span v-if="$store.state.store_mode == 1">
        <Tabs value="1" @on-click="changeTab">
          <TabPane label="小程序配置" name="1"></TabPane>
          <TabPane label="小程序上传" name="2"></TabPane>
          <TabPane label="订阅消息" name="4"></TabPane>
          <TabPane label="版本列表" name="3"></TabPane>
        </Tabs>

        <div v-if="index == '1'">
          <Alert type="error" v-if="$store.state.pay_url.wx_app_id">
            温馨提示：
            <template slot="desc">
              <div>
                登录小程序后台，选择开发-开发管理-开发设置-扫普通链接二维码打开小程序
              </div>
              <table class="set-form">
                <tr>
                  <td
                    @click="
                      copy(
                        $store.state.pay_url.url.tsapi_url +
                          $store.state.pay_url.wx_app_id
                      )
                    "
                  >
                    堂食码：{{
                      $store.state.pay_url.url.tsapi_url +
                      $store.state.pay_url.wx_app_id
                    }}
                  </td>
                  <td @click="copy('pages/ordering/ordering')">
                    小程序路径：pages/ordering/ordering
                  </td>
                </tr>
                <tr>
                  <td
                    @click="
                      copy(
                        $store.state.pay_url.url.goodsapi_url +
                          $store.state.pay_url.wx_app_id
                      )
                    "
                  >
                    商品码：{{
                      $store.state.pay_url.url.goodsapi_url +
                      $store.state.pay_url.wx_app_id
                    }}
                  </td>
                  <td @click="copy('pages/ordering/ordering')">
                    小程序路径：pages/ordering/ordering
                  </td>
                </tr>
                <tr>
                  <td
                    @click="
                      copy(
                        $store.state.pay_url.url.faceapi_url +
                          $store.state.pay_url.wx_app_id
                      )
                    "
                  >
                    面对面支付码：{{
                      $store.state.pay_url.url.faceapi_url +
                      $store.state.pay_url.wx_app_id
                    }}
                  </td>
                  <td @click="copy('pages/facepayment/facepayment')">
                    小程序路径：pages/facepayment/facepayment
                  </td>
                </tr>
                <tr>
                  <td
                    @click="
                      copy(
                        $store.state.pay_url.url.coverapi_url +
                          $store.state.pay_url.wx_app_id
                      )
                    "
                  >
                    会员绑定码：{{
                      $store.state.pay_url.url.coverapi_url +
                      $store.state.pay_url.wx_app_id
                    }}
                  </td>
                  <td @click="copy('pages/index/index')">
                    小程序路径：pages/index/index
                  </td>
                </tr>
              </table>
            </template>
          </Alert>
          <RadioGroup class="checks_box" v-model="formData.type">
            <Radio
              :class="formData.type == 2 ? 'check_kuang' : 'kuang'"
              :label="2"
              >普通商户模式</Radio
            >
            <Radio
              :class="formData.type == 1 ? 'check_kuang' : 'kuang'"
              :label="1"
              >服务商模式</Radio
            >
          </RadioGroup>
          <div style="height: 20px"></div>
          <Form
            v-if="formData.type == 2"
            label-position="left"
            :label-width="120"
          >
            <div class="title_text">支付宝配置</div>
            <FormItem label="appid：">
              <Input
                v-model="formData.app_id"
                style="width: 300px"
                placeholder="请输入appid"
              ></Input>
            </FormItem>
            <FormItem label="AES秘钥：">
              <Input
                v-model="formData.aes_key"
                style="width: 300px"
                placeholder="请输入AES秘钥"
              ></Input>
            </FormItem>
            <FormItem label="商户号：">
              <Input
                v-model="formData.merchant_no"
                style="width: 300px"
                placeholder="请输入商户号"
              ></Input>
            </FormItem>
            <FormItem label="支付宝公钥：">
              <textarea
                v-model="formData.public_key"
                placeholder="请输入支付宝公钥"
                class="textar"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </FormItem>
            <FormItem label="应用私钥：">
              <textarea
                v-model="formData.private_key"
                placeholder="请输入应用私钥"
                class="textar"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </FormItem>
            <FormItem label="">
              <Button @click="ordinaryBtn(1)" class="btn">保存</Button>
            </FormItem>
            <div class="title_text">小程序授权</div>
            <FormItem label="授权码：">
              <img :src="formData.auth_code" alt="" />
              <div style="color: #999; font-size: 12px">
                1、打开支付宝客户端扫描二维码，选择所需授权的小程序，点击确认授权
              </div>
              <div style="color: #999; font-size: 12px">
                2、授权成功之后，向客服获取应用授权令牌，填入下面的输入框
              </div>
            </FormItem>
            <FormItem label="应用授权令牌：">
              <Input
                v-model="formData.hlzw_app_auth_token"
                style="width: 300px"
                placeholder="请输入应用授权令牌"
              ></Input>
            </FormItem>
            <FormItem v-if="!formData.auth_status" label="">
              <Button @click="launchBtn">发起代运营授权</Button>
              <div style="color: #999; font-size: 12px">
                发起代运营授权后，打开支付宝客户端接受邀请并授权
              </div>
            </FormItem>
            <FormItem label="">
              <Button @click="ordinaryBtn(2)" class="btn">保存</Button>
            </FormItem>
            <!-- <div style="width:100%;height:1px;background:#999"></div> -->

            <div class="title_text">蚂蚁店铺</div>
            <FormItem label="审核状态：">
              <div v-if="Antshop.status == 0">审核中</div>
              <div v-if="Antshop.status == 1">审核成功</div>
              <div v-if="Antshop.status == 2">审核失败</div>
            </FormItem>
            <FormItem label="门店名称：">
              <Input
                v-model="Antshop.shop_name"
                style="width: 300px"
                placeholder="请输入门店名称"
              ></Input>
            </FormItem>
            <FormItem label="联系手机：">
              <Input
                v-model="Antshop.contact_mobile"
                style="width: 300px"
                placeholder="请输入联系手机"
              ></Input>
            </FormItem>
            <FormItem label="门店分类：">
              <Select v-model="Antshop.shop_category" style="width: 300px">
                <Option
                  v-for="item in StoreList"
                  :value="item.code"
                  :key="item.code"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="城市选择：">
              <CitySelect
                @select_city="select_city"
                :default_citys="default_citys"
              ></CitySelect>
            </FormItem>
            <FormItem label="具体地址：">
              <textarea
                v-model="Antshop.address"
                placeholder="请输入具体地址："
                class="textar"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </FormItem>
            <FormItem label="">
              <Button @click="addBtn(3)" class="btn">保存</Button>
            </FormItem>
          </Form>

          <Form
            v-if="formData.type == 1"
            label-position="left"
            :label-width="140"
          >
            <div class="title_text">支付宝配置</div>
            <FormItem label="服务商appid：">
              <Input
                v-model="formData.isv_app_id"
                style="width: 300px"
                placeholder="请输入服务商appid"
              ></Input>
            </FormItem>
            <FormItem label="服务商商户号：">
              <Input
                v-model="formData.isv_merchant_no"
                style="width: 300px"
                placeholder="请输入服务商商户号"
              ></Input>
            </FormItem>
            <FormItem label="服务商AES秘钥：">
              <Input
                v-model="formData.isv_aes_key"
                style="width: 300px"
                placeholder="请输入服务商AES秘钥"
              ></Input>
            </FormItem>
            <FormItem label="服务商支付宝公钥：">
              <Input
                v-model="formData.isv_public_key"
                style="width: 300px"
                placeholder="请输入服务商支付宝公钥"
              ></Input>
            </FormItem>
            <FormItem label="服务商应用私钥：">
              <Input
                v-model="formData.isv_private_key"
                style="width: 300px"
                placeholder="请输入服务商应用私钥"
              ></Input>
            </FormItem>
            <FormItem label="appid：">
              <Input
                v-model="formData.app_id"
                style="width: 300px"
                placeholder="请输入appid"
              ></Input>
            </FormItem>
            <FormItem label="AES秘钥：">
              <Input
                v-model="formData.aes_key"
                style="width: 300px"
                placeholder="请输入AES秘钥"
              ></Input>
            </FormItem>
            <FormItem label="商户号：">
              <Input
                v-model="formData.merchant_no"
                style="width: 300px"
                placeholder="请输入商户号"
              ></Input>
            </FormItem>
            <FormItem label="支付宝公钥：">
              <textarea
                v-model="formData.public_key"
                placeholder="请输入支付宝公钥"
                class="textar"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </FormItem>
            <FormItem label="应用私钥：">
              <textarea
                v-model="formData.private_key"
                placeholder="请输入应用私钥"
                class="textar"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </FormItem>
            <FormItem label="">
              <Button @click="addBtn(1)" class="btn">保存</Button>
            </FormItem>
            <!-- <div style="width:100%;height:1px;background:#999"></div> -->

            <div class="title_text">小程序授权</div>
            <!-- <FormItem label="授权码：">
                    <img :src="formData.auth_code" alt="">
                    <div style="color:#999;font-size:12px">1、打开支付宝客户端扫描二维码，选择所需授权的小程序，点击确认授权</div>
                    <div style="color:#999;font-size:12px">2、授权成功之后，向客服获取应用授权令牌，填入下面的输入框</div>
                    </FormItem> -->
            <FormItem label="应用授权令牌：">
              <Input
                v-model="formData.app_auth_token"
                style="width: 300px"
                placeholder="请输入应用授权令牌"
              ></Input>
            </FormItem>
            <FormItem v-if="!formData.auth_status" label="">
              <Button @click="launchBtn">发起代运营授权</Button>
              <div style="color: #999; font-size: 12px">
                发起代运营授权后，打开支付宝客户端接受邀请并授权
              </div>
            </FormItem>
            <FormItem label="">
              <Button @click="addBtn(2)" class="btn">保存</Button>
            </FormItem>
            <!-- <div style="width:100%;height:1px;background:#999"></div> -->

            <div class="title_text">蚂蚁店铺</div>
            <FormItem label="审核状态：">
              <div v-if="Antshop.status == 0">审核中</div>
              <div v-if="Antshop.status == 1">审核成功</div>
              <div v-if="Antshop.status == 2">审核失败</div>
            </FormItem>
            <FormItem label="门店名称：">
              <Input
                v-model="Antshop.shop_name"
                style="width: 300px"
                placeholder="请输入门店名称"
              ></Input>
            </FormItem>
            <FormItem label="联系手机：">
              <Input
                v-model="Antshop.contact_mobile"
                style="width: 300px"
                placeholder="请输入联系手机"
              ></Input>
            </FormItem>
            <FormItem label="门店分类：">
              <Select v-model="Antshop.shop_category" style="width: 300px">
                <Option
                  v-for="item in StoreList"
                  :value="item.code"
                  :key="item.code"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="城市选择：">
              <CitySelect
                @select_city="select_city"
                :default_citys="default_citys"
              ></CitySelect>
            </FormItem>
            <FormItem label="具体地址：">
              <textarea
                v-model="Antshop.address"
                placeholder="请输入具体地址："
                class="textar"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </FormItem>
            <FormItem label="">
              <Button @click="addBtn(3)" class="btn">保存</Button>
            </FormItem>
          </Form>
        </div>
        <div v-if="index == '2'">
          <div class="flex_center">
            <Form label-position="left" :label-width="100">
              <FormItem label="版本号：">
                <Input
                  style="width: 300px"
                  v-model="examineData.app_version"
                  placeholder="请输入版本号"
                ></Input>
              </FormItem>
              <FormItem label="客服手机：">
                <Input
                  style="width: 300px"
                  v-model="examineData.service_phone"
                  placeholder="请输入客服手机"
                ></Input>
              </FormItem>
              <FormItem label="版本介绍：">
                <textarea
                  v-model="examineData.version_desc"
                  placeholder="请输入版本介绍"
                  class="textar"
                ></textarea>
              </FormItem>
              <FormItem label="特殊资质：">
                <div style="display: flex">
                  <div
                    v-for="(item, index) of special_license_pic"
                    :key="index"
                    class="logo-pic"
                  >
                    <div class="clear-pic" @click="clearLogo(index)">
                      <Icon type="md-close-circle" size="20" />
                    </div>
                    <img :src="item" />
                  </div>
                  <div
                    v-if="special_license_pic.length < 3"
                    @click="openModal(1)"
                  >
                    <Upload
                      :format="['jpg', 'jpeg', 'png', 'gif']"
                      :on-format-error="handleFormatError"
                      :on-success="uploadImgSuccess1"
                      :on-progress="handleProgress"
                      :show-upload-list="false"
                      :headers="headers"
                      :action="uploadImg"
                    >
                      <div class="uplads_box">
                        <div class="center">
                          <div style="margin-top: -10px">
                            <Icon
                              style="color: #9ea6b9"
                              size="70"
                              type="ios-add"
                            />
                          </div>
                          <div
                            class="font14"
                            style="margin-top: -10px; color: #9ca7b9"
                          >
                            点击上传
                          </div>
                        </div>
                      </div>
                    </Upload>
                  </div>
                </div>
              </FormItem>
              <FormItem label="">
                <Button @click="SubmitBtn" class="btn">提交</Button>
              </FormItem>
            </Form>
            <div class="code_box">
              <Form label-position="left" :label-width="100">
                <FormItem label="小程序码：">
                  <img v-if="formData.qrcode" :src="formData.qrcode" alt="" />
                  <div>
                    <Button @click="qrcodeBtn">刷新二维码</Button>
                  </div>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
        <div v-if="index == '3'">
          <Table :columns="historycolumns" :data="historyList">
            <template slot-scope="{ row }" slot="experience_qrcode">
              <Button
                @click="lookCode(row.experience_qrcode)"
                v-if="row.experience_qrcode"
                >查看体验码</Button
              >
              <Button
                @click="cancelCode(row.app_version)"
                style="margin-left: 10px"
                v-if="row.experience_qrcode"
                >取消体验码</Button
              >
              <Button
                @click="obtainCode(row.app_version)"
                v-if="!row.experience_qrcode"
                >创建体验码</Button
              >
            </template>
            <template slot-scope="{ row }" slot="action">
              <Button
                @click="lookBtn(row.app_version)"
                type="primary"
                size="small"
                style="margin-right: 5px"
                >查看</Button
              >
              <Button
                @click="Backoff(row.app_version)"
                v-if="row.is_cancel"
                type="error"
                size="small"
                style="margin-right: 5px"
                >回退</Button
              >
              <Button
                @click="revoke(row.app_version)"
                v-if="row.is_approve_cancel"
                type="error"
                size="small"
                >撤销审核</Button
              >
              <Button
                @click="goOnline(row.app_version)"
                v-if="row.is_online"
                type="success"
                size="small"
                >上线</Button
              >
              <Button
                @click="delBtn(row.app_version)"
                v-if="row.is_delete"
                type="error"
                size="small"
                >删除</Button
              >
            </template>
          </Table>
        </div>
        <div v-if="index == '4'">
          <Form label-position="left" :label-width="120">
            <FormItem label="订阅消息提醒：">
              <i-switch
                @on-change="switchBtn"
                v-model="subscribe.status"
                :true-value="1"
                :false-value="0"
              />
            </FormItem>
            <span v-if="subscribe.status == 1">
              <FormItem
                v-for="(item, index) of subscribe.data"
                :key="index"
                :label="item.title"
              >
                <Input style="width: 300px" v-model="item.template_id"></Input>
              </FormItem>
              <FormItem label="">
                <Button @click="subscribeBtn" class="btn">提交</Button>
              </FormItem>
            </span>
          </Form>
        </div>
      </span>
      <span v-else>
        <Tabs value="1" @on-click="changeTabChain">
          <TabPane label="小程序二维码" name="1"></TabPane>
        </Tabs>

        <Form v-if="Chainindex == '1'" label-position="left" :label-width="100">
          <FormItem label="小程序码：">
            <img
              v-if="new_applet_code"
              style="width: 200px; height: 200px"
              :src="new_applet_code"
              alt=""
            />
            <div
              style="
                margin-top: 10px;
                width: 200px;
                display: flex;
                justify-content: center;
              "
            >
              <Button @click="shuacodeBtn">刷新小程序码</Button>
            </div>
          </FormItem>
        </Form>
      </span>
    </div>
    <!-- <div class="bottom_btn">
          <Button @click="addBtn" class="btn">保存</Button>
      </div> -->

    <Modal
      v-model="modaldetail"
      title="查看详情"
      width="600"
      @on-ok="okBtn"
      @on-cancel="cancelBtn"
    >
      <Form label-position="left" :label-width="100">
        <FormItem label="审核时间：">
          {{ info.gmt_audit_end ? info.gmt_audit_end : "暂无信息~" }}
        </FormItem>
        <FormItem label="拒绝原因：">
          {{ info.reject_reason ? info.reject_reason : "暂无信息~" }}
        </FormItem>
      </Form>
    </Modal>
    <Modal
      v-model="modalCode"
      title="查看体验码"
      @on-ok="okBtn"
      @on-cancel="cancelBtn"
    >
      <div style="display: flex; align-items: center; justify-content: center">
        <img :src="ZfbCode" alt="" />
      </div>
    </Modal>
  </div>
</template>

<script>
import CitySelect from "./components/CitySelect.vue";
import { getCookies, helperCopy } from "@/utils/helper";
import config from "@/config/index";
import {
  getConf,
  setConf,
  setAppToken,
  sendAuthorization,
  getShopCategory,
  getShopData,
  shopCreate,
  versionList,
  getApproveDetails,
  versionCancel,
  versionApproveCancel,
  versionApprove,
  experienceCreate,
  versionOnline,
  experienceCancel,
  versionDelete,
  setOrdinaryConf,
  setQrcode,
  getSubscribeTemplate,
  setSubscribeTemplate,
  changeSubscribeTemplate,
} from "@/api/setting";
export default {
  name: "site",
  components: { CitySelect },
  data() {
    return {
      Chainindex: "1",
      uploadImg: config.apiUrl + "/storeapi/apply/fileUpload",
      headers: {
        "author-token": getCookies("canyin_token"),
      },
      subscribe: {},
      index: "1",
      new_applet_code: "",
      info: {},
      formData: {},
      ZfbCode: "",
      default_citys: [],
      first_license_pic: "",
      special_license_pic: [],
      examineData: {
        app_version: "",
        version_desc: "",
        service_phone: "",
        first_license_pic: "", // 营业执照
        special_license_pic: [], // 特殊资质
      },
      Antshop: {},
      StoreList: [],
      modaldetail: false,
      modalCode: false,
      historycolumns: [
        {
          title: "版本号",
          key: "app_version",
          align: "center",
        },
        {
          title: "上传时间",
          key: "create_time",
          align: "center",
        },
        {
          title: "版本介绍",
          key: "version_description",
          align: "center",
        },
        {
          title: "审核状态",
          key: "version_status_name",
          align: "center",
        },
        {
          title: "体验码",
          slot: "experience_qrcode",
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          width: 240,
          align: "center",
        },
      ],
      historyList: [],
    };
  },
  created() {
    if (this.$store.state.store_mode == 2) {
      this.shuacodeBtn();
    } else {
      this.getShopData();
      this.getShopCategory();
      this.getConf();
    }
  },
  methods: {
    copy(url) {
      helperCopy(url);
      this.$Message.success("复制成功");
    },
    getConf() {
      getConf().then((res) => {
        this.formData = res.data;
      });
    },
    getShopData() {
      getShopData().then((res) => {
        this.Antshop = res.data;
        this.default_citys.push(this.Antshop.province_code);
        this.default_citys.push(this.Antshop.city_code);
        this.default_citys.push(this.Antshop.district_code);
      });
    },
    // 订阅消息模板状态
    switchBtn(e) {
      changeSubscribeTemplate({ status: e }).then((res) => {
        this.$Message.success(res.msg);
      });
    },
    // 获取小程序消息订阅模板数据
    getSubscribeTemplate() {
      getSubscribeTemplate().then((res) => {
        this.subscribe = res.data;
      });
    },
    // 订阅消息数据提交
    subscribeBtn() {
      let data = this.subscribe.data;
      setSubscribeTemplate({ data: data })
        .then((res) => {
          this.$Message.success(res.msg);
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    shuacodeBtn() {
      setQrcode()
        .then((res) => {
          this.$Message.success(res.msg);
          this.new_applet_code = res.data.qrcode;
        })
        .catch((err) => {
          this.$Message.error({
            content: err.msg,
            duration: 2,
          });
        });
    },
    getShopCategory() {
      getShopCategory().then((res) => {
        this.StoreList = res.data;
      });
    },
    versionList() {
      versionList().then((res) => {
        this.historyList = res.data;
      });
    },
    changeTabChain(e) {
      // this.infoWechat()
      this.Chainindex = e;
    },
    lookBtn(e) {
      getApproveDetails({ app_version: e }).then((res) => {
        this.info = res.data;
      });
      this.modaldetail = true;
    },
    Backoff(e) {
      versionCancel({ app_version: e })
        .then((res) => {
          this.$Message.success(res.msg);
          this.versionList();
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    revoke(e) {
      versionApproveCancel({ app_version: e })
        .then((res) => {
          this.$Message.success(res.msg);
          this.versionList();
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    delBtn(e) {
      this.$Modal.confirm({
        title: "提示",
        content: `您正在使用删除功能`,
        onOk: () => {
          versionDelete({ app_version: e })
            .then((res) => {
              this.$Message.success(res.msg);
              this.versionList();
            })
            .catch((err) => {
              this.$Message.error(err.msg);
            });
        },
      });
    },
    goOnline(e) {
      versionOnline({ app_version: e })
        .then((res) => {
          this.$Message.success(res.msg);
          this.versionList();
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    SubmitBtn() {
      versionApprove(this.examineData)
        .then((res) => {
          this.$Message.success(res.msg);
          this.examineData = {
            app_version: "",
            version_desc: "",
            service_phone: "",
            first_license_pic: "",
            special_license_pic: [],
          };
          this.special_license_pic = [];
          // this.versionList()
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    lookCode(e) {
      this.ZfbCode = e;
      this.modalCode = true;
    },
    cancelCode(e) {
      experienceCancel({ app_version: e })
        .then((res) => {
          this.$Message.success(res.msg);
          this.versionList();
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    obtainCode(e) {
      experienceCreate({ app_version: e })
        .then((res) => {
          this.$Message.success(res.msg);
          let that = this;
          setTimeout(() => {
            that.versionList();
          }, 1000);
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    clearLogo(e) {
      this.examineData.special_license_pic.splice(e, 1);
      this.special_license_pic.splice(e, 1);
    },
    select_city(e) {
      this.default_citys = e.city_number;
    },
    ordinaryBtn(e) {
      let data;
      if (e == 1) {
        data = {
          app_id: this.formData.app_id,
          public_key: this.formData.public_key,
          private_key: this.formData.private_key,
          aes_key: this.formData.aes_key,
          merchant_no: this.formData.merchant_no,
        };
        setOrdinaryConf(data)
          .then((res) => {
            this.$Message.success(res.msg);
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else if (e == 2) {
        data = { hlzw_app_auth_token: this.formData.hlzw_app_auth_token };
        setAppToken(data)
          .then((res) => {
            this.$Message.success(res.msg);
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      }
    },
    okBtn() {},
    cancelBtn() {},
    qrcodeBtn() {
      setQrcode().then((res) => {
        this.$Message.success(res.msg);
        this.formData.qrcode = res.data.qrcode;
        this.$forceUpdate();
      });
    },
    backBtn() {
      this.$emit("backview", 0);
    },
    addBtn(e) {
      let data;
      if (e == 1) {
        data = {
          isv_aes_key: this.formData.isv_aes_key,
          isv_app_id: this.formData.isv_app_id,
          isv_merchant_no: this.formData.isv_merchant_no,
          isv_private_key: this.formData.isv_private_key,
          isv_public_key: this.formData.isv_public_key,
          aes_key: this.formData.aes_key,
          app_id: this.formData.app_id,
          merchant_no: this.formData.merchant_no,
          private_key: this.formData.private_key,
          public_key: this.formData.public_key,
        };
        setConf(data)
          .then((res) => {
            this.$Message.success(res.msg);
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else if (e == 2) {
        data = { app_auth_token: this.formData.app_auth_token };
        setAppToken(data)
          .then((res) => {
            this.$Message.success(res.msg);
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else if (e == 3) {
        this.Antshop.province_code = this.default_citys[0];
        this.Antshop.city_code = this.default_citys[1];
        this.Antshop.district_code = this.default_citys[2];
        shopCreate(this.Antshop)
          .then((res) => {
            this.$Message.success(res.msg);
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      }
    },
    launchBtn() {
      sendAuthorization()
        .then((res) => {
          this.$Message.success(res.msg);
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    changeTab(e) {
      if (e == "3") {
        this.versionList();
      }
      if (e == "4") {
        this.getSubscribeTemplate();
      }
      // if (e=='3') {
      //     this.getShopData()
      //     this.getShopCategory()
      // }
      this.index = e;
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //添加图片上传图片成功
    uploadImgSuccess(e) {
      this.first_license_pic = e.data.path;
      this.examineData.first_license_pic = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    uploadImgSuccess1(e) {
      this.special_license_pic.push(e.data.path);
      this.examineData.special_license_pic.push(e.data.src);
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
  },
};
</script>

<style scoped>
.son-page {
  position: relative;
}
.imgBox {
  width: 300px;
  text-align: center;
  margin: 0 auto;
}
.back_box {
  width: 100%;
  cursor: pointer;
  background: #fff;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 30px;
}
.content_box {
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-top: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
}
.ivu-switch-checked {
  border-color: #ff761d;
  background-color: #ff761d;
}
.bottom_btn {
  /* position: absolute;
    bottom: 0;
    right: 0; */
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 100px;
  border-top: 1px solid #eee;
  background: #fff;
}
.flex_text {
  display: flex;
  align-items: center;
}
.img_view_Box {
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-right: 20px;
  overflow: hidden;
}
.img_view_Box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
.logo-pic {
  width: 105px;
  height: 105px;
  position: relative;
  border: 1px solid #a0a4bd;
  /* overflow: hidden; */
  border-radius: 5px;
  margin-right: 10px;
}
.logo-pic img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  border-radius: 6px;
}
.clear-pic {
  position: absolute;
  top: -14px;
  right: -8px;
  z-index: 9999;
  color: red;
  cursor: pointer;
}
.textar {
  border: 1px solid #dcdee2;
  outline: none;
  width: 300px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}
.fath_box {
  position: relative;
}
.DW_code {
  position: absolute;
  top: 50px;
  left: 500px;
  text-align: center;
}
.DW_code img {
  width: 150px;
  height: 150px;
}
.kuang {
  border: 1px solid #eee;
  padding: 5px 16px;
  border-radius: 5px;
}
.check_kuang {
  border: 1px solid #ff761d;
  color: #ff761d;
  padding: 5px 16px;
  border-radius: 5px;
}
.title_text {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}
.flex_center {
  display: flex;
}
.code_box {
  margin-left: 50px;
}
.code_box img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
</style>
<style>
.ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}
.ivu-btn-primary {
  background: #ff761d;
  border-color: #ff761d;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #ff761d;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #ff761d;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #ff761d;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  transform-origin: 0 0;
}
.shop_flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ivu-radio-checked .ivu-radio-inner {
  border-color: #ff761d;
}
.ivu-radio-inner:after {
  background-color: #ff761d;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
}
.showLoginBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.set-form td {
  padding: 5px 10px 5px 0;
  cursor: pointer;
}
</style>