<template>
  <div>
    <div v-if="Select == 0" class="son-page">
      <div
        @click="menuclick(item)"
        :style="'background:url(' + item.icon + ') no-repeat 100% 100%;'"
        v-for="(item, index) of menus"
        :key="index"
        class="item_box"
      >
        <div>{{ item.title }}</div>
      </div>
    </div>
    <div v-if="Select == 'Sharepolitely'">
      <Share @backview="backview"></Share>
    </div>
    <div v-if="Select == 'Skydropredenvelope'">
      <Envelopes @backview="backview"></Envelopes>
    </div>
    <div v-if="Select == 'Newcomerreduction'">
      <Order @backview="backview"></Order>
    </div>
  </div>
</template>

<script>
import Envelopes from "./marketing/envelopes";
import Order from "./marketing/order";
import Share from "./marketing/share";
import { getCookies } from "@/utils/helper";
export default {
  components: { Envelopes, Order, Share },
  name: "password",
  data() {
    return {
      Select: 0,
      menus: [],
    };
  },
  created() {
    let menu = this.$store.state.menu;
    menu.forEach((item) => {
      if (item.id == 17) {
        item.children.forEach((j) => {
          if (j.id == 38) {
            this.menus = j.children;
            console.log("j.children", j.children);
          }
        });
      }
    });
  },
  methods: {
    menuclick(e) {
      if (e.title == "收银") {
        let url = `${location.origin}/cash/#/?token=${getCookies(
          "canyin_token"
        )}`;
        window.open(url, "_blank");
      } else {
        this.Select = e.src;
      }
    },
    backview(e) {
      this.Select = e;
    },
  },
};
</script>

<style scoped>
.son-page {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.item_box {
  cursor: pointer;
  width: 23%;
  height: 100px;
  border-radius: 12px;
  background: #fff;
  margin: 10px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
